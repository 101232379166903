import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getApiUrlForPayroll, getLicenseKey } from "./application.settings";

const api = service()

export const getStaffListByUserTypeId = (userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByUserTypeId/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByUserTypeId - " + error.message)
            console.log('error caught in service : getStaffListByUserTypeId')
        },
    );

export const getProfileForStaff = (staffDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProfileForStaff/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProfileForStaff - " + error.message)
            console.log('error caught in service : getProfileForStaff')
        },
    );

export const updateStaffRole = (addStaffRolePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffRole`, addStaffRolePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffRole')
        }
    );

export const updateStaffRoleForPayroll = (addStaffRolePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `updateStaffRole`, addStaffRolePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffRole')
        }
    );


export const updateStaffResigningDetails = (exitOrResignPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffResigningDetails`, exitOrResignPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffResigningDetails')
        }
    );

export const updateStaffResigningDetailsForPayroll = (exitOrResignPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `updateStaffResigningDetails`, exitOrResignPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffResigningDetails')
        }
    );

export const deleteStaffDetails = (staffDeletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffDetails`, staffDeletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDetails')
        }
    );

export const deleteStaffDetailsForPayroll = (staffDeletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `deleteStaffDetails`, staffDeletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDetails')
        }
    );

export const getStaffDegree = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDegree/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDegree - " + error.message)
            console.log('error caught in service : getStaffDegree')
        },
    );

export const addStaffDetails = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffDetails`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDetails')
        }
    );

export const addStaffDetailsForPayroll = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `addStaffDetails`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDetails')
        }
    );


export const getStaffDetails = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDetails - " + error.message)
            console.log('error caught in service : getStaffDetails')
        },
    );

export const getStaffDetailsForPayroll = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getApiUrlForPayroll() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDetails - " + error.message)
            console.log('error caught in service : getStaffDetails')
        },
    );

export const updateStaffDetails = (updateStaffDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffDetails`, updateStaffDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDetails')
        }
    );

export const updateStaffDetailsForPayroll = (updateStaffDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `updateStaffDetails`, updateStaffDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDetails')
        }
    );

export const getStaffListByDepartmentId = (departmentId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByDepartmentId/${getLicenseKey}/${departmentId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByDepartmentId - " + error.message)
            console.log('error caught in service : getStaffListByDepartmentId')
        },
    );

export const getDeletedStaffList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDeletedStaffList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDeletedStaffList - " + error.message)
            console.log('error caught in service : getDeletedStaffList')
        },
    );

export const rejoinStaffDetails = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejoinStaffDetails`, staffRejoinPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStaffDetails')
        }
    );


export const rejoinStaffDetailsForPayroll = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `rejoinStaffDetails`, staffRejoinPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStaffDetails')
        }
    );

// export const getActivityLog = (userTypeId: any, studentOrStaffDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
// ) => api.get(
//     getAPIURL() + `getActivityLog/${getLicenseKey}/${userTypeId}/${studentOrStaffDetailsId}/${fromDateInMilliSec}/${toDateInMilliSec}`,
//     (response) => {
//         success(response);
//     },
//     (error) => {
//         failure('getActivityLog - ' + error.message);
//         console.log('error caught in service : getActivityLog');
//     },
// );
export const getActivityLog = ( studentOrStaffDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
    getAPIURL() + `getActivityLog/${getLicenseKey}/${studentOrStaffDetailsId}/${fromDateInMilliSec}/${toDateInMilliSec}`,
    (response) => {
        success(response);
    },
    (error) => {
        failure('getActivityLog - ' + error.message);
        console.log('error caught in service : getActivityLog');
    },
);