import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../../hooks/useDarkMode';
import { getFirstLetter, priceFormat } from '../../../../helpers/helpers';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { demoPagesMenu } from '../../../../menu';
import { toasts } from '../../../../services/toast.service';
import { getFeeCollectionReportByPaymentType } from '../../../../services/dashboard.service';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import dayjs from 'dayjs';
import AuthContext from '../../../../contexts/authContext';
import { TableLoader } from '../../../../services/loader.services';

const FeesCollection = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userAccountId != '') {
			handleOpenModal();
		}
	}, []);


	const [dataStatus, setDataStatus] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [feeData, setFeeData] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [nodataForPaidBills, setNodataForPaidBills] = useState('')

	const handleOpenModal = () => {
		let todayDate = getCurrentDateAndTime('date')
		let fromDate = getCurrentDateAndTime('date')
		let today = convertDateToEpoch(todayDate)
		let fromDay = convertDateToEpoch(fromDate)
		getFeeCollectionReportByPaymentTypeList(userAccountId, fromDay, today);
	};


	function getFeeCollectionReportByPaymentTypeList(userAccountId: any, fromDay: any, today: any) {
		setDataStatus(false);
		getFeeCollectionReportByPaymentType(userAccountId, fromDay, today,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.feeCollectionReportByPaymentType;
					if (data != undefined) {
						setIsLoader(false);
						setDataStatus(true);
						setFeeData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].id = data[i].paymentTypeId.toString();
						}
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					setFeeData([]);
					setNodataForPaidBills(response.data.message)
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	// const TopSalesItem: FC<ITopSalesItemProps> = ({ name, type, price, count }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel icon='Collections' iconColor='info'>
					<CardTitle tag='div' className='h5'>
						Fees Collection
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody style={{ height: '50vh' }}>
				{!dataStatus ?
					<TableLoader /> :
					<>
						{feeData != '' ?
							<>
								{feeData.map((i: any) => (
									<div className='col-12' key={i.paymentTypeId}>
										<div className='row mb-1'>
											<div className='row mb-1'>
												<div className=' col d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
															<div
																className={classNames(
																	'rounded-2',
																	'd-flex align-items-center justify-content-center',
																	{
																		'bg-l10-dark': !darkModeStatus,
																		'bg-l90-dark': darkModeStatus,
																	},
																)}>
																<span className='fw-bold'>{getFirstLetter(i.paymentType)}</span>
															</div>
														</div>
													</div>
													<div className='flex-grow-1'>
														<div className='fs-6'>{i.paymentType}</div>
														<div className='text-muted'>
															<small>Total Bill Count</small>
														</div>
													</div>
												</div>
												<div className='col-auto text-end'>
													<div>
														{i.totalCollected != 0 ? <strong>{priceFormat(i.totalCollected)}</strong> : <strong>₹ 0</strong>}
													</div>
													<div className='text-muted'>
														<small>{i.totalBillCount}</small>
													</div>
												</div>
											</div>
										</div>
									</div>))}
							</>
							:
							<div className='row'>
								<div className='col-12'>
									<div className='text-center align-items-center'>
										<div className='fs-6'>{nodataForPaidBills}</div>
									</div>
								</div>
							</div>}
					</>
				}
			</CardBody>
		</Card>
	);
};

export default FeesCollection;
