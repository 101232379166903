import { useContext, useEffect, useState } from "react";
import SearchableSelect from "../../../common/components/SearchableSelect";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Collapse from "../../bootstrap/Collapse";
import FormGroup from "../../bootstrap/forms/FormGroup";
import { getStaffListByUserTypeId } from "../../../services/staff.service";
import useDarkMode from "../../../hooks/useDarkMode";
import { useFormik } from "formik";
import { convertDateToEpoch, getCurrentDateAndTime } from "../../../services/common.service";
import { toasts } from "../../../services/toast.service";
import { getScheduleDetailsByDate } from "../../../services/timeTable.service";
import Input from "../../bootstrap/forms/Input";
import Button from "../../bootstrap/Button";
import AuthContext from "../../../contexts/authContext";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { getLicenseKey } from "../../../services/application.settings";
import { addStudentAttendanceForWeb } from "../../../services/attendance.service";
import { showLoader } from "../../../services/loader.services";
import { addSubstitutionStaffAllocation } from "../../../services/administration.service";
import AlertService from "../../../services/AlertService";
import classNames from "classnames";

function SubstitutionPage() {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getStaffListByUserType(7)
    }, []);

    const { themeStatus, darkModeStatus } = useDarkMode();

    const [absentStaffDetailsId, setAbsentStaffDetailsId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [scheduleData, setScheduleData] = useState<any>([]);
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const staffSubstitutionForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                date?: string;
            } = {};

            if (!values.date) {
                errors.date = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectAbsentStaff = (e: any) => {
        let absentStaffDetailsId = e
        setAbsentStaffDetailsId(absentStaffDetailsId)
    }

    function viewStaffList() {
        let scheduleDate = staffSubstitutionForm.values.date;
        let dateInMilliSec = convertDateToEpoch(scheduleDate);
        setDataSuccess(false)
        setIsLoader(true);
        getScheduleDetailsList(7, absentStaffDetailsId?.value, dateInMilliSec)
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScheduleDetailsList(userTypeId: any, staffDetailsId: any, dateInMilliSec: any) {
        getScheduleDetailsByDate(
            userTypeId,
            staffDetailsId,
            dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scheduleDetailsByDate[0].period;
                    if (data != undefined) {
                        setIsOpenListCard(false)
                        setIsLoader(false);
                        setScheduleData(data);
                        setData(data);
                        setDataSuccess(true);
                        // setStaffDetailsId(data.substitutionStaffDetailsId != null ? { value: data.substitutionStaffDetailsId, label: data.substitutionName } : '')
                        console.log({ value: data.substitutionStaffDetailsId, label: data.substitutionName });
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                            data[i].staffDetailsIdForShow = data[i].substitutionStaffDetailsId != 0 ? { value: data[i].substitutionStaffDetailsId, label: data[i].substitutionName } : ''
                            data[i].remarks = data[i].remarks != ''? data[i].remarks: ''
                            console.log({ value: data[i].substitutionStaffDetailsId, label: data[i].substitutionName });

                        }
                        
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true);
                        toasts('Undefined Data', 'Error');
                        setIsOpenListCard(false);
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    // toasts(response.data.message, "Error")
                    setScheduleData([]);
                    setIsOpenListCard(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                    setIsOpenListCard(false);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    const [data, setData] = useState<any>([])
    const [staffSubstitutionArray, setStaffSubstitutionArray] = useState<any>([])

    const updateSubstituteStaff = (e: any, timetableMasterDetailsId: any, remarks : any) => {

        let substitutionStaffDetailsId = e?.value;
        let staffDetailsIdForShow = e;

        const newData: any = [];

        data.forEach((item: any, index: any) => {
            if (item['timetableMasterDetailsId'] == timetableMasterDetailsId) {
                item['substitutionStaffDetailsId'] = substitutionStaffDetailsId
                item['timetableMasterDetailsId'] = timetableMasterDetailsId
                item['staffDetailsIdForShow'] = staffDetailsIdForShow
                item['remarks'] = remarks
            }
            newData.push(item)
        });
        setData(newData)

        if (staffSubstitutionArray.length == 0 && substitutionStaffDetailsId > 0) {
            const obj = {
                timetableMasterDetailsId: timetableMasterDetailsId,
                substitutionStaffDetailsId: substitutionStaffDetailsId,
                staffDetailsIdForShow: staffDetailsIdForShow,
                remarks: remarks,
            };
            staffSubstitutionArray.push(obj);
        } else if (substitutionStaffDetailsId > 0) {
            let selectedItem: any;
            staffSubstitutionArray.forEach((item: any, index: any) => {
                if (item['timetableMasterDetailsId'] == timetableMasterDetailsId) {
                    selectedItem = item
                    selectedItem['substitutionStaffDetailsId'] = substitutionStaffDetailsId
                    selectedItem['staffDetailsIdForShow'] = staffDetailsIdForShow
                    selectedItem['remarks'] = remarks
                }
            });
            if (selectedItem != undefined) {
                selectedItem['timetableMasterDetailsId'] = timetableMasterDetailsId,
                    selectedItem['substitutionStaffDetailsId'] = substitutionStaffDetailsId
                selectedItem['staffDetailsIdForShow'] = staffDetailsIdForShow
                selectedItem['remarks'] = remarks
            } else {
                var obj
                obj = {
                    timetableMasterDetailsId: timetableMasterDetailsId,
                    substitutionStaffDetailsId: substitutionStaffDetailsId,
                    staffDetailsIdForShow: staffDetailsIdForShow,
                    remarks: remarks,
                };
                staffSubstitutionArray.push(obj);
            }
        }
    }

    const updateRemarksValue = (remarks: any, timetableMasterDetailsId: number) => {
        const newData: any = [];

        data.forEach((item: any, index: any) => {
            if (item['timetableMasterDetailsId'] == timetableMasterDetailsId) {
                item['remarks'] = remarks
            }
            newData.push(item)
        });

        setData(newData)

        if (staffSubstitutionArray.length == 0) {
            const obj = {
                timetableMasterDetailsId: timetableMasterDetailsId,
                remarks: remarks,
            };
            staffSubstitutionArray.push(obj);
        } else {
            let selectedItem: any;
            staffSubstitutionArray.forEach((item: any, index: any) => {
                if (item['timetableMasterDetailsId'] == timetableMasterDetailsId) {
                    selectedItem = item
                    selectedItem['remarks'] = remarks
                }
            });
            if (selectedItem != undefined) {
                selectedItem['remarks'] = remarks
            } else {
                var obj
                obj = {
                    timetableMasterDetailsId: timetableMasterDetailsId,
                    remarks: remarks,
                };

                staffSubstitutionArray.push(obj);
            }
        }
    }

    function setStaffSubstitution() {
        return ({
            substitutionStaffAllocation: staffSubstitutionArray,
            scheduleDate : staffSubstitutionForm.values.date,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
        })
    }

    function staffSubstitutionSubmit() {
        showLoader(true);
        if (staffSubstitutionArray.length > 0) {
            let studentAttendancePostData = setStaffSubstitution();
            console.log(studentAttendancePostData);
            addSubstitutionStaffAllocation(studentAttendancePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setStaffSubstitutionArray([])
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                        setStaffDetailsId([])
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (staffSubstitutionArray.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    return (
        <PageWrapper>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Staff Substitution Creation
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                {/* <div className='col-3'>
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> */}
                                <div className="col-lg-3">
                                    <FormGroup id='absentStaffDetailsId' label='Select Staff' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Staff Name' placeholder="Select Staff"
                                            onChange={selectAbsentStaff}
                                            value={absentStaffDetailsId}
                                            list={staffDetailsData.map((data: any) => (
                                                { value: data.staffDetailsId, label: data.empAndStaffName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {absentStaffDetailsId?.value != undefined ? (
                                    <div className='col-md-2'>
                                        <FormGroup id='date' label='Date' isFloating>
                                            <Input
                                                onChange={staffSubstitutionForm.handleChange}
                                                value={staffSubstitutionForm.values.date}
                                                onBlur={staffSubstitutionForm.handleBlur}
                                                isValid={staffSubstitutionForm.isValid}
                                                isTouched={staffSubstitutionForm.touched.date}
                                                invalidFeedback={staffSubstitutionForm.errors.date}
                                                type='date'
                                            //min={getCurrentDateAndTime('date')}
                                            />
                                        </FormGroup>
                                    </div>
                                ) : null}
                                {staffSubstitutionForm.values.date != '' && absentStaffDetailsId?.value != undefined ? (
                                    <div className='col-3 align-self-center'>
                                        <Button icon='ArrowDownward' isDisable={absentStaffDetailsId?.value == undefined} color='primary' onClick={viewStaffList}>View</Button>
                                    </div>
                                ) : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='Schedule' iconColor='primary'>
                                <CardTitle>
                                    Schedule List
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            {scheduleData != '' ?
                                <div className="row">
                                    <div className="col-lg-12">
                                        <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                            <thead>
                                                <tr className='text-center table-primary fs-6'>
                                                    <th>Period</th>
                                                    <th>Subject</th>
                                                    <th>Course/Batch</th>
                                                    <th>Semester/Section</th>
                                                    <th>Substitute Staff</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {scheduleData.map((i: any) => (
                                                    <tr key={i.timetableMasterDetailsId}>
                                                        <td className='text-center'>
                                                            {/* <b>{i.periodCount}</b> */}
                                                            <span className="fw-bold">
                                                                <span className='ms-1 text-info fs-3'>
                                                                    {i.periodCount}
                                                                </span>
                                                            </span>
                                                            {/* <div className='flex-shrink-0'>
                                                            <div
                                                                className='ratio ratio-1x1'
                                                                style={{ width: 40 }}>
                                                                <div
                                                                    className={classNames(
                                                                        'rounded-2',
                                                                        'd-flex align-items-center justify-content-center',
                                                                        {
                                                                            'bg-l10-info':
                                                                                !darkModeStatus,
                                                                            'bg-lo25-info':
                                                                                darkModeStatus,
                                                                        },
                                                                    )}>
                                                                    <span className='fw-bold'>
                                                                        <span className='ms-1 text-info fs-2'>
                                                                            {i.periodCount}
                                                                        </span><br />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        </td>
                                                        <td className='text-center'>{i.paperName}</td>
                                                        <td className='text-center'>
                                                            <span >
                                                                <span className=' text-dark '>
                                                                    {i.courseName}
                                                                </span>
                                                            </span> <br />
                                                            <span >
                                                                <span className=' text-primary '>
                                                                    {i.batchName}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td className='text-center'>
                                                            <span >
                                                                <span className=' text-dark '>
                                                                    {i.semesterNumber}
                                                                </span>
                                                            </span> <br />
                                                            <span >
                                                                <span className=' text-primary '>
                                                                    Section - {i.sectionName.substring(0, 1)}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td style={{ width: '300px'}}>
                                                            <div className='col-lg-12' >
                                                                <FormGroup id="staffDetailsId">
                                                                    <SearchableSelect
                                                                        ariaLabel=''
                                                                        placeholder='Select Staff'
                                                                        onChange={(e) => updateSubstituteStaff(e, i.timetableMasterDetailsId, i.remarks)}
                                                                        value={i.staffDetailsIdForShow}
                                                                        list={staffDetailsData.map((data: any) => (
                                                                            { value: data.staffDetailsId, label: data.empAndStaffName }
                                                                        ))}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </td>
                                                        <td scope='col'>
                                                            <div className="col-12">
                                                                <FormGroup id='remarks'>
                                                                    <Input type="text" value={i.remarks} onInput={(e: any) => updateRemarksValue(e.target.value, i.timetableMasterDetailsId)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-lg-12 d-flex justify-content-center">
                                        <FormGroup>
                                            <Button icon="Save" color="info" type="submit" onClick={() => { staffSubstitutionSubmit() }}
                                            // isDisable={!routeMasterForm.isValid && !!routeMasterForm.submitCount && staffSubstitutionArray.length == 0}
                                            >
                                                Save
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <div className='row'>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                Schedule Not Allocated
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </CardBody>
                    </Card>
                    : null
                }
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}
export default SubstitutionPage;