import React, { useContext, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import classNames from 'classnames';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import HumansWebp from '../../../../assets/img/scene2.webp';
import Humans from '../../../../assets/img/scene2.png';
import Icon from '../../../../components/icon/Icon';
import { getScheduleDetailsByDate } from '../../../../services/timeTable.service';
import { TableLoader } from '../../../../services/loader.services';

const StaffPeriodSchedule = () => {

    useEffect(() => {
        getScheduleDetailsList();
    }, []);

    const [scheduleData, setScheduleData] = useState<any>([]);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [dayOrder, setDayOrder] = useState<any>('')
    const [dayId, setDayId] = useState<any>('')
    const [dataStatus, setDataStatus] = useState<any>(false)

    const [c, setC] = useState('')
    const [sem, setSem] = useState('')
    const [sec, setSec] = useState('')

    function getScheduleDetailsList() {

        let currentDate = getCurrentDateAndTime('date')
        let dateInMilliSec = convertDateToEpoch(currentDate)

        getScheduleDetailsByDate(
            userTypeId,
            userAccountId,
            dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scheduleDetailsByDate[0].period;
                    if (data != undefined) {
                        setDataStatus(true)
                        setC(data[0].courseName);
                        setSem(data[0].semesterNumber);
                        setSec(data[0].sectionName);
                        setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
                        setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
                        setScheduleData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                        setDataStatus(true)
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setScheduleData([]);
                    setDataStatus(true)
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                    setDataStatus(true)
                }
            },
            (error) => {
                toasts(error, 'Error');
                setDataStatus(true)
            },
        );
    }


    return (
        <PageWrapper title='Schedule'>
            {/* <SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='Schedule' iconColor='info'>
						<CardTitle tag='div' className='h5'>
							Schedule
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
			</SubHeader> */}
            <Page container='fluid'>
                {!dataStatus ?
                    <TableLoader /> :
                    <>
                        {scheduleData != '' && dayId > 0 && dayId != 7 ? (
                            <div className='col-12'>
                                <div className='row'>
                                    {scheduleData.map((period: any) => (
                                        <div className='col-6' key={period.periodDetailsId}>
                                            <Card stretch shadow={'md'} className="border border-light">
                                                <CardBody >
                                                    <div className='row g-3 align-items-center'>
                                                        <div className='col d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <div
                                                                    className='ratio ratio-1x1'
                                                                    style={{ width: 65 }}>
                                                                    <div
                                                                        className={classNames(
                                                                            'rounded-2',
                                                                            'd-flex align-items-center justify-content-center',
                                                                            {
                                                                                'bg-l10-info':
                                                                                    !darkModeStatus,
                                                                                'bg-lo25-info':
                                                                                    darkModeStatus,
                                                                            },
                                                                        )}>
                                                                        <span className='fw-bold'>
                                                                            <span className='ms-1 text-info fs-2'>
                                                                                {period.periodCount}
                                                                            </span><br />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                                                <div>
                                                                    <small className='fw-bold'>
                                                                        {period.paperName}
                                                                    </small>
                                                                    {userTypeId == 1 ?
                                                                        <div className=' text-info fs-6 mb-0'>
                                                                            {period.staffName}
                                                                        </div> :
                                                                        <>
                                                                            <div className=' text-primary fs-6 mb-0'>
                                                                                {period.courseName}
                                                                            </div>
                                                                            <div className=' text-success fs-6 mb-0'>
                                                                                {sem} - ({sec.substring(0, 1)})
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className='text-info'>
                                                                        <small>
                                                                            <span className='text-black-50 fw-bold'>
                                                                                {period.startTime}-{period.endTime}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    ))}{' '}
                                </div>
                            </div>
                        ) : (
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    {scheduleData != '' && (dayId == 0 || dayId == 7) ?
                                        <div className='row'>
                                            <div className="col-12" style={{ height: '10vh' }}></div>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Icon icon='CalendarToday' size={'10x'} />
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                Holiday
                                            </div>
                                        </div> :
                                        <div className='row'>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                {noDataMsg}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </>

                }
            </Page>
        </PageWrapper>
    );
};

export default StaffPeriodSchedule;
