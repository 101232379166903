import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getPeriod = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPeriod/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getPeriod - " + error.message)
            console.log('error caught in service : getPeriod')
        },
    );

export const addPeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addPeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addPeriod')
        }
    );

export const updatePeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updatePeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updatePeriod')
        }
    );

export const deletePeriod = (periodFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deletePeriod`, periodFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deletePeriod')
        }
    );

export const getAllPeriod = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllPeriod/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllPeriod - " + error.message)
            console.log('error caught in service : getAllPeriod')
        },
    );

export const getShift = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getShift/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getShift - " + error.message)
            console.log('error caught in service : getShift')
        },
    );

export const getAllocatedTimeTable = (courseId: number, batchMasterId: number, semesterDetailsId: number, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllocatedTimeTable/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllocatedTimeTable - " + error.message)
            console.log('error caught in service : getAllocatedTimeTable')
        },
    );

export const getPeriodListByShiftId = (shiftId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPeriod/${getLicenseKey}/${shiftId}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getPeriod - " + error.message)
            console.log('error caught in service : getPeriod')
        },
    );

export const getAllocatedTimeTableMaster = (courseId: number, batchMasterId: number, semesterDetailsId: number, sectionDetailsId: number, dayId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllocatedTimeTable/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}/${dayId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllocatedTimeTable - " + error.message)
            console.log('error caught in service : getAllocatedTimeTable')
        },
    );
export const getDayOrder = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDayOrderType/${getLicenseKey}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getDayOrderType - " + error.message)
            console.log('error caught in service : getDayOrderType')
        },
    );
export const addClassTimeTable = (timeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addClassTimeTable`, timeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addClassTimeTable')
        }
    );
export const updateTimeTableMasterDetails = (timeTableEditPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTimeTableMasterDetails`, timeTableEditPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTimeTableMasterDetails')
        }
    );
export const deleteTimeTableMasterDetails = (delTimeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTimeTableMasterDetails`, delTimeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTimeTableMasterDetails')
        }
    );


export const getAllocatedTimeTableList = (courseId: number, batchMasterId: number, semesterDetailsId: number, sectionDetailsId: number, dayOrderId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAllocatedTimeTable/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}/${dayOrderId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllocatedTimeTable - " + error.message)
            console.log('error caught in service : getAllocatedTimeTable')
        },
    );

export const getScheduleDetailsByDate = (userTypeId: number, userAccountId: number, date: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScheduleDetailsByDate/${getLicenseKey}/${userTypeId}/${userAccountId}/${date}`,
        response => {
            success(response)
        },
        error => {
            failure("getScheduleDetailsByDate - " + error.message)
            console.log('error caught in service : getScheduleDetailsByDate')
        },
    );