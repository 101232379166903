import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import dayjs from 'dayjs';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';
import { TableLoader } from '../../../../services/loader.services';
import { priceFormat } from '../../../../helpers/helpers';

const TodayFeeCollection = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userTypeId != '' && userTypeId != '') {
			getDashboardDataByUserTypeIdList(userTypeId, userAccountId);
		}
	}, []);


	const { themeStatus, darkModeStatus } = useDarkMode();
	const [dataStatus, setDataStatus] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [dashboardDataByUserTypeData, setDashboardDataByUserTypeData] = useState<any>([])


	function getDashboardDataByUserTypeIdList(userTypeId: any, userAccountId: any) {
		setDataStatus(false);
		getDashboardDataByUserTypeId(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dashboardDataByUserTypeId[0];
					if (data != undefined) {
						setDataStatus(true);
						setDashboardDataByUserTypeData(data);
					}
					else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			{userAccountId == 240 ?
				<div className='row g-4 align-items-center'>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-success'>{dashboardDataByUserTypeData.getTodayTotalReceivedAmount != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayTotalReceivedAmount) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Received</div>
									</div>
									<div className='col-2'>
										<Icon icon='Checklist' size='3x' color='success' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-danger'>{dashboardDataByUserTypeData.getBalanceAmountToBePaid != undefined ? priceFormat(dashboardDataByUserTypeData.getBalanceAmountToBePaid) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Balance</div>
									</div>

									<div className='col-2'>
										<Icon icon='Report' size='3x' color='danger' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-primary'>{dashboardDataByUserTypeData.getTodayReceivedAmountByCash != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayReceivedAmountByCash) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Cash</div>
									</div>

									<div className='col-2'>
										<Icon icon='Money' size='3x' color='primary' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTodayReceivedAmountByOthers != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayReceivedAmountByOthers) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Others</div>
									</div>

									<div className='col-2'>
										<Icon icon='GridView' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
				:
				<div className='row g-4 align-items-center'>
					<div className='col-xl-6'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-success'>{dashboardDataByUserTypeData.getTodayTotalReceivedAmount != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayTotalReceivedAmount) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Received</div>
									</div>
									<div className='col-2'>
										<Icon icon='Checklist' size='3x' color='success' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-6'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-danger'>{dashboardDataByUserTypeData.getBalanceAmountToBePaid != undefined ? priceFormat(dashboardDataByUserTypeData.getBalanceAmountToBePaid) : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold text-muted fs-6 mb-0'>Balance</div>
									</div>

									<div className='col-2'>
										<Icon icon='Report' size='3x' color='danger' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			}
		</>
	);
};

export default TodayFeeCollection;
